const dev = {
    GOOGLE_RECAPTCHA_KEY: "6LdrnbwbAAAAABpJa_6lb3Soe85X14s6PDOkpHPe",
    API_ENDPOINT_URL: "http://localhost:8000",
    APP_URL: "https://localhost:3000"
};

const prod = {
    GOOGLE_RECAPTCHA_KEY: "6LfoZLwbAAAAAB_mIgzPmcDww0AjxYO412WL2QAe",
    API_ENDPOINT_URL: "https://api.dtekt.io",
    APP_URL: "https://app.dtekt.io"
};

const getConfig = () => {
    switch (process.env.NODE_ENV) {
        case "development":
            return dev;
        case "production":
            return prod;
        default:
            return dev;
    }
};

export default getConfig