import React from "react";

const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const SaasOnepage = React.lazy(() => import("./pages/Saas Onepage/index"));


const routes = [
  { path: "/", component: SaasOnepage, isWithoutLayout: false, exact: true },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
