import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import getConfig from "./config";

const config = getConfig();

const app = (
  // <GoogleReCaptchaProvider
  //   reCaptchaKey={config.GOOGLE_RECAPTCHA_KEY}
  //   useEnterprise={false}
  // >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </GoogleReCaptchaProvider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
